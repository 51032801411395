import { useDispatch, useSelector } from "react-redux";
import BookIcon from "assets/images/icons/book-icon.svg";
import CategoryIcon from "assets/images/icons/category-icon.svg";
import CSRIcon from "assets/images/icons/csr.png";
import DpIconWhiteGreen from "assets/images/icons/dp-icon-white-green.svg";
import FaqIcon from "assets/images/icons/faq-icon.svg";
import HowToUseDpIcon from "assets/images/icons/how-to-use-dp.svg";
import LogOutIcon from "assets/images/icons/logout.svg";
import NewspaperIcon from "assets/images/icons/newspaper-icon.svg";
import PackingIcon from "assets/images/icons/packing-icon.svg";
import QuestionMarkIcon from "assets/images/icons/question-mark-icon.svg";
import RegisterIcon from "assets/images/icons/register-icon.svg";
import SigninIcon from "assets/images/icons/signin-icon.svg";
import SpeechBubbleIcon from "assets/images/icons/speech-bubble-icon.svg";
import useCurrentUser from "hooks/useCurrentUser";
import { setBurgerMenuOpen } from "reduxStore/slices/nav-bar/actions";
import { selectBurgerMenuOpen } from "reduxStore/slices/nav-bar/selectors";

import LinkButton from "components/atoms/LinkButton";
import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";
import GenericModal from "components/molecules/GenericModal";

type MenuLinkProps = {
  name: string;
  icon: string;
  url: string;
}
const MenuLink = ({ name, icon, url }: MenuLinkProps) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setBurgerMenuOpen(false));
  };
  return <VersionSensitiveLink
    onClick={onClick}
    className="flex flex-row py-2 px-4 w-full hover:bg-dpLightGreen rounded-lg"
    to={url}
  >
    <img src={icon} alt="name" className="w-8 mr-4"/>
    <div className="text-xl text-dpGreen whitespace-nowrap">{name}</div>
  </VersionSensitiveLink>;
};

const BurgerMenu = () => {
  const isOpen = useSelector(selectBurgerMenuOpen);
  const dispatch = useDispatch();
  const onDismiss = () => {
    dispatch(setBurgerMenuOpen(false));
  };

  const { user } = useCurrentUser();

  return <GenericModal
    isOpen={isOpen}
    onDismiss={onDismiss}
    className="absolute right-0 top-0 bg-white w-full xs:w-auto h-full xs:h-auto shadow-sym-md p-8 xs:rounded-bl-lg overflow-y-auto"
    withBackdrop={true}
  >
    <div>
      <div className="w-full text-right">
        <LinkButton onClick={onDismiss} text="╳" className="font-bold"/>
      </div>
      <MenuLink name="Home" icon={DpIconWhiteGreen} url="/"/>
      <MenuLink name="How to use DropPoint" icon={HowToUseDpIcon} url="/how-to-use-droppoint"/>
      <MenuLink name="Donation Categories" icon={CategoryIcon} url="/categories"/>

      <MenuLink name="Packing Instructions" icon={PackingIcon} url="/packingguide"/>
      <MenuLink name="How we help Charities" icon={QuestionMarkIcon} url="/how-we-help"/>
      <MenuLink name="How we help Businesses" icon={CSRIcon} url="/csr"/>
      <MenuLink name="Our story" icon={BookIcon} url="/ourstory"/>
      <MenuLink name="In the news" icon={NewspaperIcon} url="/in-the-news"/>
      <MenuLink name="FAQs" icon={FaqIcon} url="/faq"/>
      <MenuLink name="Contact us" icon={SpeechBubbleIcon} url="/contact"/>
      {
        user && <>
          <MenuLink name="Account" icon={SigninIcon} url="/charity-admin/home"/>
          <MenuLink name="Log out" icon={LogOutIcon} url="/logout"/>
        </>
      }
      {
        !user && <>
          <MenuLink name="Sign in" icon={SigninIcon} url="/charity-admin/home"/>
          <MenuLink name="Register" icon={RegisterIcon} url="/signup"/>
        </>
      }
    </div>
  </GenericModal>;
};

export default BurgerMenu;